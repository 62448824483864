import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function CookieComponent() {
  const [cookieValue, setCookieValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get the value of the cookie named 'myCookie'
    const CF_Authorization_FooBar = Cookies.get('CF_Authorization', { domain: 'foobar.wesleymonte.com' });

    // If the cookie value is not empty, set it as the component's state value
    if (CF_Authorization_FooBar !== '') {
      setCookieValue(CF_Authorization_FooBar);
      setLoading(false);
    } else {
      // If the cookie value is empty, call a function to update it
      updateCookie();
    }
  }, []);

  function updateCookie() {
    // Execute an HTTP request to set the value of the cookie
    fetch('https://foobar.wesleymonte.com/login')
      .then(response => response.text())
      .then(() => {
        const cf_authorization = Cookies.get('CF_Authorization', { domain: 'foobar.wesleymonte.com' });
        if (cf_authorization !== '') {
          // If the cookie was successfully set, update the component's state value
          // Cookies.set('myCookie', data.cookieValue);
          // setCookieValue(data.cookieValue);
          setLoading(false);
        } else {
          // If the cookie could not be set, set the component's state value to "failed"
          setCookieValue('failed');
          setLoading(false);
        }
      });
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        cookieValue === 'failed' ? (
          <p>Cookie update failed.</p>
        ) : (
          <p>Cookie value: {cookieValue}</p>
        )
      )}
    </div>
  );
}

export default CookieComponent;
