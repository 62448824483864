import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { login } from './redux/authSlice';
import axios from 'axios';
import Cookies from 'js-cookie';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const CF_Authorization = Cookies.get('CF_Authorization');
      // Create a new Headers object
      const headers = {
        'Cookie': `CF_Authorization=${CF_Authorization}`
      }; 

      const response = await axios.post('https://foobar.wesleymonte.com/login', {
        email,
        password,
      }, { headers });
      const { token } = response.data;
      console.log(response);
      dispatch(login(token));
      message.success('Logged in successfully!');
    } catch (error) {
      message.error('Failed to log in. Please try again later.');
    }
  };

  return (
    <div>
      <h1>Login Page</h1>
      <Input
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <br />
      <Input.Password
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <br />
      <Button type="primary" onClick={handleLogin}>
        Login
      </Button>
    </div>
  );
};

export default LoginPage;
